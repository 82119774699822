<template>
	<div>
		<CustomerBindWhExa :showAdd="false"></CustomerBindWhExa>
	</div>
</template>
<script>
	import CustomerBindWhExa from '@/components/WarehouseCenter2/AccountBind/CustomerBindWhExa.vue';

	export default {
		components: {
			CustomerBindWhExa
		},
		data() {
			return {

			};
		},

		//创建时
		created() {},
		//编译挂载前
		mounted() {},
		methods: {

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>